import React from 'react';
import { graphql } from 'gatsby';
import { FooterArt, Attributes, Farms, SoWhatNow, KeyArt, Layout, PageContent, Quiz, Seo, StickyContent, WidthContainer } from '../components';
import { FadeIn } from '../components/Animation/FadeIn';
import { Video } from '../components/Home/Video/Video';
import { Aquaculture } from '../components/Home/Aquaculture/Aquaculture';
import { DidYouKnow } from '../components/Home/DidYouKnow/DidYouKnow';
import { OceanFriendlyLiving } from '../components/Home/OceanFriendlyLiving/OceanFriendlyLiving';
import { FishTales } from '../components/Home/FishTales/FishTales';

export default ({ data }) => {
    const fields = data.wpPage.homeFields;
    console.log(fields.homeQuizResultsButton);
    

    return (
        <Layout isHome={true}>
            <Seo title='Wave to Table' description="test" />
            <KeyArt />
            <PageContent>
                <Video heading={fields.homeVideoHeading} strapline={fields.homeVideoStrapline} videoUrl={'https://www.youtube.com/embed/MeOh_NuIKwM'} />
                <div className="Quiz__FullContainer">
                    <img className="Quiz__FishSideLeft" src="/images/fish-sides.svg" alt="Decorative fish illustation" />

                    <WidthContainer>
                            <Quiz resultsButton={fields.homeQuizResultsButton} heading={fields.homeQuizHeading} strapline={fields.homeQuizStrapline} questions={fields.homeQuizQuestions} />
                    </WidthContainer>

                    <img className="Quiz__FishSideRight" src="/images/fish-sides.svg" alt="Decorative fish illustation" />
                </div>
                <Aquaculture heading={fields.homeAquacultureHeading} text={fields.homeAquacultureText} button={fields.homeAquacultureButton} />
                <DidYouKnow heading={fields.homeDykHeading} text={fields.homeDykText} />
                <OceanFriendlyLiving heading={fields.homeOceanfriendlylivingHeading} text={fields.homeOceanfriendlylivingText} button={fields.homeOceanfriendlylivingButton} />
                <DidYouKnow heading={fields.homeDyk2Heading} text={fields.homeDyk2Text} />
                
                <WidthContainer>

                    {/* <StickyContent heading={fields.homeDidYouKnowHeading} facts={fields.homeDidYouKnowFacts} /> */}
                    {/* <SoWhatNow header={fields.homeCtaHeading} text={fields.homeCtaText} button={fields.homeCtaButton} />
                    <Farms heading={fields.homeFarmsHeading} text={fields.homeFarmsText} button={fields.homeFarmsButton} farms={fields.homeFarmsFarms} />
                    <Attributes heading={fields.homeAttributesHeading} text={fields.homeAttributesText} button={fields.homeAttributesButton} /> */}
                    <FishTales heading={fields.homeFishtalesHeading} text={fields.homeFishtalesText} button={fields.homeFishtalesButton} />
                </WidthContainer>
            </PageContent>
            <FooterArt />
        </Layout>
    );
};

export const query = graphql`
    query ($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            slug
            id
            homeFields {
                homeOceanfriendlylivingHeading
                homeOceanfriendlylivingText
                homeOceanfriendlylivingButton {
                    url
                    title
                    target
                }
                homeDykHeading
                homeDykText
                homeDyk2Heading
                homeDyk2Text
                homeAquacultureHeading
                homeAquacultureText
                homeAquacultureButton {
                    target
                    title
                    url
                }
                homeVideoHeading
                homeVideoStrapline
                homeVideoVideo {
                    mimeType
                    mediaItemUrl
                }                
                homeQuizHeading
                homeQuizStrapline
                homeQuizQuestions {
                    answer
                    question
                }
                homeQuizResultsButton {
                    target
                    title
                    url
                }
                homeFishtalesHeading
                homeFishtalesText
                homeFishtalesButton {
                    target
                    title
                    url
                }
            }
        }
    }
`;
