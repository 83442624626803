import React from 'react';
import { Button, WidthContainer } from '../..';
import { FadeIn } from '../../Animation/FadeIn';
import './OceanFriendlyLiving.scss';

interface IOceanFriendlyLivingProps {
    heading: string;
    text: string;
    button: {
        url: string;
        title: string;
        target: string;
    }
}

export const OceanFriendlyLiving: React.FC<IOceanFriendlyLivingProps> = (props) => {
    return (
        <WidthContainer>
            <FadeIn>
            <div className="OceanFriendlyLiving">
                <div className="OceanFriendlyLiving__Left">
                    <FadeIn>
                        <h3 dangerouslySetInnerHTML={{ __html: props.heading }}></h3>
                        <p>{props.text}</p>
                        <Button as='a' href={props.button.url} target={props.button.target}>{props.button.title}</Button>
                    </FadeIn>
                </div>
                <div className="OceanFriendlyLiving__Right">
                    <FadeIn>
                        <img src='/images/whale-fish.svg' alt='Decorative scuba diver illustration' />
                    </FadeIn>
                </div>
            </div>
            </FadeIn>
        </WidthContainer>
    )
}