import React from 'react';
import { Button, WidthContainer } from '../..';
import { FadeIn } from '../../Animation/FadeIn';
import './Aquaculture.scss';

interface IAquacultureProps {
    heading: string;
    text: string;
    button: {
        url: string;
        title: string;
        target: string;
    }
}

export const Aquaculture: React.FC<IAquacultureProps> = (props) => {
    return (
        <WidthContainer>
            <FadeIn>
            <div className="HomeAquaculture">
                <div className="Aquaculture__Left">
                    <FadeIn>
                        <img src='/images/scuba.svg' alt='Decorative scuba diver illustration' />
                    </FadeIn>
                </div>
                <div className="Aquaculture__Right">
                    <FadeIn>
                        <h3 dangerouslySetInnerHTML={{ __html: props.heading }}></h3>
                        <p>{props.text}</p>
                        <Button as='a' href={props.button.url} target={props.button.target}>{props.button.title}</Button>
                    </FadeIn>
                </div>
            </div>
            </FadeIn>
        </WidthContainer>
    )
}