import React from 'react';
import { Button } from '../../';
import { FadeIn } from '../../Animation/FadeIn';
import './FishTales.scss';

interface IFishTalesProps {
    heading: string;
    text: string;
    button: {
        title: string;
        target: string;
        url: string;
    }
}

export const FishTales: React.FC<IFishTalesProps> = props => {
    return (
        <FadeIn>
        <div className="FishTales">
            <h3 dangerouslySetInnerHTML={{ __html: props.heading }} />
            <p>To ensure that we at <span>Pacific Catch</span> only serve you ethically sourced
seafood, we need to know every fish’s story or as we call it,
a “fish tale”.<br /><br />

Find out where we source all our fish and seafood.</p>
            <Button as="link" href={props.button.url} target={props.button.target}>{props.button.title}</Button>
        </div>
        </FadeIn>

    );
};
